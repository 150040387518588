
import { computed, defineComponent, WritableComputedRef } from "vue"
import AdminButton from "@/components/admin/AdminButton.vue"

export default defineComponent({
  name: "AdminDialog",
  components: {
    AdminButton,
  },
  props: {
    agreementDialog: {
      type: Boolean,
      required: false,
      default: false,
    },
    disagreeText: {
      type: String,
      required: false,
      default: "いいえ",
    },
    agreeText: {
      type: String,
      required: false,
      default: "はい",
    },
    disagreeButton: {
      type: Boolean,
      required: false,
      default: true,
    },
    agreeButton: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  emits: [
    "update:agreementDialog",
    "onAgreeClick",
    "onDisagreeClick",
    "update:disagreeButton",
    "update:agreeButton",
  ],

  setup: function (props, { emit }) {
    // const onAgreeClick = (): void => {
    //   emit("agree")
    // }
    const dialog: WritableComputedRef<boolean> = computed({
      get(): boolean {
        return props.agreementDialog
      },
      set(): void {
        emit("update:agreementDialog", dialog)
      },
    })

    const disagree: WritableComputedRef<boolean> = computed({
      get(): boolean {
        return props.disagreeButton
      },
      set(): void {
        emit("update:disagreeButton", disagree)
      },
    })

    const agree: WritableComputedRef<boolean> = computed({
      get(): boolean {
        return props.agreeButton
      },
      set(): void {
        emit("update:agreeButton", agree)
      },
    })

    const onAgreeClick = (): void => {
      emit("onAgreeClick")
    }

    const onDisagreeClick = (): void => {
      emit("onDisagreeClick")
    }

    return {
      onAgreeClick,
      onDisagreeClick,
      dialog,
      disagree,
      agree,
    }
  },
})
